import { queryAllByClass } from '../shared/util'

let links

const ACTIVE_CLASS = 'active'

function init () {
  links = queryAllByClass('js-nav-link')
  if (!links.length) return

  links.forEach((link) => {
    if (document.location.href === link.href) {
      link.classList.add(ACTIVE_CLASS)
    }
  })
}

export default init
