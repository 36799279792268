import { queryByClass } from '../shared/util';
import {
    getBreakPoint,
    BREAKPOINTS
} from '../shared/breakpoints';

let hero;

function init(){
  hero = queryByClass('js-blog-hero');
  if (!hero) return;

  setImgSource();
}


function setImgSource() {
    let breakPoint = getBreakPoint(),
        isMobile = breakPoint === BREAKPOINTS.MOBILE || breakPoint === BREAKPOINTS.MOBILE_RETINA,
        bgUrl = isMobile ? hero.dataset.mobileImg : hero.dataset.desktopImg;
    hero.style.backgroundImage = `url(${bgUrl})`;
}

export default init;
