import { queryAllByClass } from '../shared/util';

let authorBoxes;

function init() {
  authorBoxes = queryAllByClass('js-blog-author-box');

  if (!authorBoxes) return;

  authorBoxes.forEach((box) => {
    box.addEventListener('click', (event) => {
      if (event.target.nodeName !== 'A') {
        let url = box.dataset.href;
        window.location.href = url;
      }
    });
  });
}

export default init;
