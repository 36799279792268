import {
  queryByClass,
  queryAllByClass,
  debounce,
  getOffset
} from '../shared/util';
import {
  setupResizeHandeler
} from '../shared/breakpoints';
import PubSub from 'pubsub-js';
import Keys from '../shared/pubsub-keys';

let blogPostsContainer,
  allPostImages,
  allPostPositions;

const BLOG_POSTS_CONTAINER = 'js-blog-posts-grid',
  LAZY_LOAD_CLASS = 'js-lazy-load-img',
  IMAGE_IS_LOADED = 'js-image-is-loaded',
  LOADING_SHOW = 'loading';

function init() {
  blogPostsContainer = queryByClass(BLOG_POSTS_CONTAINER);

  if (blogPostsContainer) {
    allPostImages = queryAllByClass(LAZY_LOAD_CLASS, blogPostsContainer);

    getAllPostPositions();
    PubSub.subscribe(Keys.SCROLL, tryExecuteLazyLoad);
    PubSub.subscribe(Keys.RESIZE_WIDTH, getAllPostPositions);
    setupScrollHandler(Keys.SCROLL);
    setupResizeHandeler();
  }
}

function getAllPostPositions() {
  allPostPositions = allPostImages.reduce((previous, post, index) => {
    if (!post.classList.contains(IMAGE_IS_LOADED)) {
      var postOffset = getOffset(post);

      let postObject = {
        'postNr': index,
        'offsetTop': postOffset.top
      };
      previous.push(postObject);
    }
    return previous;
  }, []);

  tryExecuteLazyLoad();
}

function tryExecuteLazyLoad() {
  let windowBottomPos = window.pageYOffset + window.innerHeight;

  // Loads images for blog posts which are above bottom of screen and has not yet been loaded
  if (allPostPositions.length) {
    let postPosToBeRemoved = [];
    allPostPositions.forEach((post, index) => {
      if (post.offsetTop < windowBottomPos) {
        let bgImg = allPostImages[post.postNr].dataset.src,
            newImg = new Image();

        allPostImages[post.postNr].classList.add(LOADING_SHOW);

        allPostImages[post.postNr].style.backgroundImage = 'url(' + bgImg + ')';
        newImg.src = bgImg;

        if (newImg.complete) {
            allPostImages[post.postNr].classList.remove(LOADING_SHOW);
            allPostImages[post.postNr].classList.add(IMAGE_IS_LOADED);
        } else {
            newImg.addEventListener('load', () => {
                allPostImages[post.postNr].classList.remove(LOADING_SHOW);
                allPostImages[post.postNr].classList.add(IMAGE_IS_LOADED);
            });
        }
        postPosToBeRemoved.unshift(index);
      }
    });

    // Remove all images from the allPostPositions array that has been loaded
    postPosToBeRemoved.forEach((value) => {
      allPostPositions.splice(value, 1);
    });
  }
}

function setupScrollHandler() {
    let debouncedScrollHandler = debounce(() => {
        PubSub.publish(Keys.SCROLL);
    }, 100);
    window.addEventListener('scroll', debouncedScrollHandler);
}

export default init;
